<template>
  <div class="post-details-box usually-box">
    <div class="post-descript">
      <h3>{{post.name}}</h3>
      <ul class="info">
        <li>
          工作地点：{{post.place}}
        </li>
        <li>
          职位类别：{{post.position}}
        </li>
        <li>
          薪资待遇：{{post.salary}}
        </li>
        <li>
          招聘人数：{{post.recruitNum}}
        </li>
        <li>
          发布日期：{{post.releaseTime}}
        </li>
      </ul>
    </div>
    <div class="post-details usually-box">
      <h3>岗位描述：</h3>
      <div v-html="post.jobDescription">
      </div>
      <h3>任职要求：</h3>
      <div v-html="post.jobRequirements">
      </div>
      <h3>福利待遇：</h3>
      <div v-html="post.fringeBenefits">
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onBeforeMount, toRefs } from 'vue'
const msg = reactive({
  post: {}
})
onBeforeMount(() => {
  msg.post = JSON.parse(sessionStorage.getItem('jobItem'))
})
const { post } = toRefs(msg)
console.log(post)
</script>
<style lang='scss' scoped>
.post-details-box {
  padding-top: $headerHeight + 50px;
  padding-bottom: 80px;
  background-color: $contactColor;
}
.post-descript {
  margin-bottom: 50px;
  padding: 30px 30px 50px;
  background-color: #ffffff;
  // border-radius: 10px;
  // box-shadow: 0 0 10px 5px rgba(0 0 0 / 50%);
  > h3 {
    font-size: 22px;
    padding: 0 0 15px 0;
  }
  ul {
    > li {
      font-size: 16px;
      line-height: 1.8em;
      margin-bottom: 5px;
    }
  }
}
.post-details {
  margin-bottom: 50px;
  padding: 0 30px 50px;
  background-color: #fff;
  // border-radius: 10px;
  // box-shadow: 0 0 10px 5px rgba(0 0 0 / 50%);
  > h3 {
    font-size: 22px;
    padding: 30px 0 15px;
  }
  > p {
    white-space: pre;
  }
}
</style>